exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-o-firmie-tsx": () => import("./../../../src/pages/o-firmie.tsx" /* webpackChunkName: "component---src-pages-o-firmie-tsx" */),
  "component---src-pages-tajemniczy-klient-tsx": () => import("./../../../src/pages/tajemniczy-klient.tsx" /* webpackChunkName: "component---src-pages-tajemniczy-klient-tsx" */),
  "component---src-templates-blog-index-template-tsx": () => import("./../../../src/templates/blogIndexTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-index-template-tsx" */),
  "component---src-templates-default-tsx": () => import("./../../../src/templates/default.tsx" /* webpackChunkName: "component---src-templates-default-tsx" */),
  "component---src-templates-marketing-research-index-tsx": () => import("./../../../src/templates/marketingResearchIndex.tsx" /* webpackChunkName: "component---src-templates-marketing-research-index-tsx" */),
  "component---src-templates-marketing-research-single-tsx": () => import("./../../../src/templates/marketingResearchSingle.tsx" /* webpackChunkName: "component---src-templates-marketing-research-single-tsx" */),
  "component---src-templates-single-blog-post-template-tsx": () => import("./../../../src/templates/singleBlogPostTemplate.tsx" /* webpackChunkName: "component---src-templates-single-blog-post-template-tsx" */)
}

